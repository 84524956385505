import React from 'react';
import '../styles/mainHeader.css';
import LinkNYCLogo from '../assets/img/LinkNYC.gif';

const MainHeader = () => {
  return (
    <header>
      <div className="container-fluid">
        <div>
          <a href="/">
            <img src={LinkNYCLogo} alt={"LinkNYC logo"}/>
          </a>
        </div>
        <div className="links">
          <a href="/find-a-link.html">Find a Link</a>
          <a target="_blank" href="https://www.intersection.com/linknyc/" rel="noreferrer">Advertise on Link</a>
          <a href="/faq.html">FAQ</a>
          <a href="/press">Press</a>
          <a href="/contact.html">Contact</a>
        </div>
      </div>
    </header>
  );
}

export default MainHeader;
