import React, {useEffect} from 'react';
import {WordPressPost} from "./interfaces";
import Sidebar from "./Sidebar";
import defaultTitleBackgroundImage from '../../assets/img/press-releases.jpg';
import '../../styles/pressReleases.css';

interface NewsPostProps {
    post: WordPressPost
}

const NewsPost = (props: NewsPostProps) => {
    const { post } = props;
    const backgroundImage = post.featured_media ? post.featured_media : defaultTitleBackgroundImage;

    const postContentSection1 = {
        backgroundImage: `url(${backgroundImage})`
    }

    useEffect(() => {
        // Set the browser tab title
        document.title = "Press | LinkNYC";

        // Optional cleanup if you want to reset the title on unmount
        return () => {
            document.title = "LinkNYC";
        };
    }, []); // Empty dependency array to run only once when the component mounts

    const convertFontSizeClassesToInlineStyles = (postContent: string) => {
        // Create a map of WordPress font size classes to their respective font sizes
        const fontSizeClassMap = {
            'has-small-font-size': '14px',
            'has-medium-font-size': '17px',
            'has-large-font-size': '22px',
            'has-extra-large-font-size': '26px',
        };

        for (const [className, fontSize] of Object.entries(fontSizeClassMap)) {
            const regex = new RegExp(`class="([^"]*\\s)?${className}(\\s[^"]*)?"`, 'g');
            postContent = postContent.replace(regex, (match) => {
                return match.replace(`class="${className}"`, `style="font-size: ${fontSize};"`);
            });
        }

        return postContent;
    }
    const convertWpToHtml = (wpString: string) => {
        // Remove WordPress block comments and retain only HTML tags
        return wpString
            .replace(/<!-- wp:.*?-->/g, '')     // Remove opening comments like <!-- wp:paragraph -->
            .replace(/<!-- \/wp:.*?-->/g, '');  // Remove closing comments like <!-- /wp:paragraph -->
    };

    const convertedContent = convertWpToHtml(post.content);
    const htmlOutput = convertFontSizeClassesToInlineStyles(convertedContent);

    const postContent = (
        <div className='post-content'>
            <h3>
                <div></div>
            </h3>
            <div className='post-content-date'>{post.post_date}</div>
            <div className='post-content-inner-text' dangerouslySetInnerHTML={{ __html: htmlOutput }} ></div>
        </div>
    );

    return (
        <div className='top-div'>
            <section className='post-content-section-1' style={postContentSection1}>
                <div className='post-content-section-1-div'>
                    <h2>
                        <div></div>
                        {post.title}
                    </h2>
                </div>
            </section>
            <section className='post-content-section-2'>
                <Sidebar />
                {postContent}
            </section>
        </div>
    );
}

export default NewsPost;
