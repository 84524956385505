import React, { useEffect } from "react";

interface TitleProps {
    title: string;
    children: React.ReactNode;
}

const Title: React.FC<TitleProps> = ({ title, children }) => {
    useEffect(() => {
        document.title = title;
    }, [title]);

    return <>{children}</>;
};

export default Title;
